import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "content"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "desc" }

import { ref } from 'vue';
import { ElLoading } from 'element-plus';
import Empty from './components/empty.vue';
import { getUserSystems } from '@/api/systems';
import { useLoginUserDetailStore } from '@/stores/useLoginUserDetailStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const userDetailStore = useLoginUserDetailStore();
userDetailStore.tryLoadUserDetail();

const currentUrl = ref(window.location.href);
const systemData = ref<any[]>([]);

const getData = async () => {
  const loadingInst = ElLoading.service();
  try {
    const { code, data } = await getUserSystems({
      sid: ''
    });
    if (code === 0) {
      systemData.value = data.systems;
    }
  } catch {
    systemData.value = [];
  } finally {
    loadingInst.close();
  }
};
getData();

const onSystemClick = (system: any) => {
  window.location.href = `${system.sysUrl}?sid=${system.sid}&ref=${encodeURIComponent(
    window.location.href
  )}`;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (systemData.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(systemData.value, (system) => {
            return (_openBlock(), _createElementBlock("a", {
              key: system.sid,
              class: "system-item",
              style: _normalizeStyle(`background-image:url(${system.portalIcon})`),
              href: `${system.sysUrl}?sid=${system.sid}&ref=${encodeURIComponent(currentUrl.value)}`,
              target: "_blank"
            }, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(system.sysName), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(system.sysDesc), 1)
            ], 12, _hoisted_3))
          }), 128)),
          _cache[0] || (_cache[0] = _createElementVNode("i", null, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("i", null, null, -1)),
          _cache[2] || (_cache[2] = _createElementVNode("i", null, null, -1)),
          _cache[3] || (_cache[3] = _createElementVNode("i", null, null, -1))
        ]))
      : (_openBlock(), _createBlock(Empty, { key: 1 }))
  ]))
}
}

})