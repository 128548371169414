import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "empty" }
const _hoisted_2 = ["src"]

import { ref } from 'vue';
import emptyImg from '@/assets/images/no-access.png';

export default /*@__PURE__*/_defineComponent({
  __name: 'empty',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "empty-img",
      src: _unref(emptyImg)
    }, null, 8, _hoisted_2)
  ]))
}
}

})